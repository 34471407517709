//调用政策库的接口
import $http from './index'
//获取企业类型
export const getEpStyle=()=>{
  return $http.get(`/api/Enterprise/GetEpStyle`);
}
//获取企业状态
export const getEpState=()=>{
  return $http.get(`/api/Enterprise/GetEpState`);
}
//获取注册资本类型列表
export const getCapitalTypeList=()=>{
  return $http.get(`/api/Enterprise/GetCapitalTypeList`);
}
//获取企业指标信息
export const getEpItemList=(data)=>{
  return $http.post(`/api/Enterprise/GetEpItemList`,data);
}
//获取企业信息
export const getEpInfo=(epId)=>{
  return $http.get(`/api/Enterprise/GetEpInfo?epId=${epId}`);
}
//获取企业列表
export const getEpList=(data)=>{
  return $http.post(`/api/Enterprise/GetEpList`,data);
}
//获取企业数量
export const getEpNum=(data)=>{
  return $http.post(`/api/Enterprise/GetEpNum`,data);
}
//获取企业的标签列表
export const getLabelListOfEp=(data)=>{
  return $http.post(`/api/Enterprise/GetLabelListOfEp`,data);
}
//获取标签列表
export const getLabelList=(data)=>{
  return $http.post(`/api/Label/GetLabelList`,data);
}
//获取指标项数据的最新时间
export const getItemDataDate=(tbName)=>{
  return $http.get(`/api/Enterprise/GetItemDataDate?tbName=${tbName}`);
}
//获取企业当前时间指标项的值及增速
export const getItemDataOfEp=(data)=>{
  return $http.post(`/api/Enterprise/GetItemDataOfEp`,data);
}
//获取企业的最近时间段的指标数据列表,同一表中多个企业
export const getItemDataListOfEp=(data)=>{
  return $http.post(`/api/Enterprise/GetItemDataListOfEp`,data);
}
//获取企业信息排名，如成立日期、参保人数
export const getRankingOfEp=(data)=>{
  return $http.post(`/api/Enterprise/GetRankingOfEp`,data);
}
//获取企业指标排名
export const getItemRankingOfEp=(data)=>{
  return $http.post(`/api/Enterprise/GetItemRankingOfEp`,data);
}
//获取z计分模型的z值范围内某行业的企业数量
export const getZJFRangeEpNum=(ecoIndustryCodea, ecoIndustryCodeb, date)=>{
  return $http.post(`/api/Enterprise/GetZJFRangeEpNum?ecoIndustryCodea=${ecoIndustryCodea}&ecoIndustryCodeb=${ecoIndustryCodeb}&date=${date}`);
}
//获取巴萨利模型值范围内某行业的企业数量
export const getBSLRangeEpNum=(ecoIndustryCodea, ecoIndustryCodeb, date)=>{
  return $http.post(`/api/Enterprise/GetBSLRangeEpNum?ecoIndustryCodea=${ecoIndustryCodea}&ecoIndustryCodeb=${ecoIndustryCodeb}&date=${date}`);
}
export const exportEpList=(data)=>{
  return $http.post(`/api/Enterprise/ExportEpList`,data);
}
////***********************地区企业*************** */
//获取地区企业的行业门类列表
export const getRegionIndustryList=(regionCode)=>{
  return $http.get(`/api/Enterprise/GetRegionIndustryList?regionCode=`+regionCode);
}
//获取地区指标数据列表（多地区多指标多时间）
export const getRegionItemDataList=(data)=>{
  return $http.post(`/api/Enterprise/GetRegionItemDataList`,data);
}
//下级地区的指标数据
export const getNextRegionItemData=(data)=>{
  return $http.post(`/api/Enterprise/GetNextRegionItemData`,data);
}
//根据条件获取企业的数量
export const getRegionEpCount=(data)=>{
  return $http.post(`/api/Enterprise/GetRegionEpCount`,data);
}

//获取企业列表
export const getRegionEpList=(data)=>{
  return $http.post(`/api/Enterprise/GetRegionEpList`,data);
}
//获取地区企业的坐标
export const getRegionEpLnglat=(data)=>{
  return $http.post(`/api/Enterprise/GetRegionEpLnglat`,data);
}
//获取地区企业的工商信息
export const getRegionEpBusinessInfo=(companyId,regionCode)=>{
  return $http.get(`/api/Enterprise/GetRegionEpBusinessInfo?companyId=${companyId}&province=${regionCode}`);
}
//获取地区企业的扩展信息
export const getRegionEpExtendInfo=(companyId)=>{
  return $http.get(`/api/Enterprise/GetRegionEpExtendInfo?companyId=${companyId}`);
}
//获取地区企业的联系方式（电话、邮箱）
export const getRegionEpContactInfo=(companyId)=>{
  return $http.get(`/api/Enterprise/GetRegionEpContactInfo?companyId=${companyId}`);
}
//获取地区企业的网址
export const getRegionEpIcpInfo=(companyId)=>{
  return $http.get(`/api/Enterprise/GetRegionEpIcpInfo?companyId=${companyId}`);
}
//获取地区企业的分析信息
export const getRegionEpAnalysis=(companyId)=>{
  return $http.get(`/api/Enterprise/GetRegionEpAnalysis?companyId=${companyId}`);
}
//获取地区企业的标签列表
export const getLabelListOfRegionEp=(data)=>{
  return $http.post(`/api/Enterprise/GetLabelListOfRegionEp`,data);
}
//获取企业信息排名，如成立日期、参保人数
export const getRankingOfRegionEp=(data)=>{
  return $http.post(`/api/Enterprise/GetRankingOfRegionEp`,data);
}

