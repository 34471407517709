<template>
<Header></Header>
<div class="main-c">
    <div style="padding:20px;background-color:#fff;border-radius:2px;margin:20px;">
        <span class="fr">更新时间：{{enterpriseInfo?.updateDate!=null? parseTime( new Date(enterpriseInfo?.updateDate),'{y}年'):''}}</span>
        <div style="min-height: 100px;margin-bottom: 20px;position: relative; ">
            <el-image :src="enterpriseInfo?.logoUrl"  class="ep-logo" >
                <template #error>
                    <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
            <div style="padding-left:130px;">
                <h1 style="margin-bottom:10px">{{enterpriseInfo?.name}}</h1>
                    <span class="neirong">{{enterpriseInfo?.stateName}}</span>
                   <div>
                       <span>联系电话：{{enterpriseInfo?.tel}}</span>
                        <span class="ml-10">邮箱：{{enterpriseInfo?.mail}}</span>
                        <span v-if='enterpriseInfo?.url' class="ml-10">网址：
                            <el-link  :href="'/#/Link?target='+ ( enterpriseInfo?.url.indexOf('http')==0 || enterpriseInfo?.url.indexOf('https')==0?enterpriseInfo?.url:'http://'+enterpriseInfo?.url)" target='_blank'>{{ enterpriseInfo?.url}}</el-link>
                        </span>
                    </div>
            </div>
            <!-- <download v-if='$route.meta.btnList!=null && $route.meta.btnList.indexOf("btnDownload")>-1' reportName='重点企业分析' fileName='重点企业分析报告' :par='enterpriseInfo?.entId.toString()' style="position:absolute;right:0;top:0;">报告下载</download> -->
        </div>
        <el-row class="fontsize16 row">
            <el-col :span="8">
                <div>
                    <span class="de_label">法人：</span>
                    <span>{{enterpriseInfo?.legalperson}}</span>
                </div>
            </el-col>
            <el-col :span="8">
                <div>
                    <span class="de_label">注册资本：</span>
                    <span>{{enterpriseInfo?.registeredcapital? enterpriseInfo?.registeredcapital+'万'+enterpriseInfo?.capitalTypeName:'-'}}</span>
                    <span class="fs-small" :class="registeredcapitalRankinig?.className">
                        {{registeredcapitalRankinig?.val}}
                        <el-tooltip v-if='registeredcapitalRankinig'
                            effect="light"
                            content="本省同行业同类型的企业"
                            placement="right-start">
                            <i class="el-icon-question" ></i>
                        </el-tooltip>
                    </span>
                </div>
            </el-col>
            <el-col :span="8">
                 <div>
                    <span class="de_label">统一社会信用代码：</span>
                    <span>{{enterpriseInfo?.organizationCode}}</span>
                </div>
            </el-col>
        </el-row>
        <el-row class="fontsize16 row">
            <el-col :span="8">
                <div>
                    <span class="de_label">成立日期：</span>
                    <span>{{enterpriseInfo?.createDate!=null? parseTime( new Date(enterpriseInfo?.createDate),'{y}-{m}-{d}')+'('+enterpriseInfo.age+'年)':''}}</span>
                    <span class="fs-small" :class="createDateRankinig?.className">
                        {{createDateRankinig?.val}}
                        <el-tooltip v-if='createDateRankinig'
                            effect="light"
                            content="本省同行业的企业"
                            placement="right-start">
                            <i class="el-icon-question" ></i>
                        </el-tooltip>
                    </span>
                </div>
            </el-col>
            <el-col :span="8">
                 <div>
                    <span class="de_label">企业类型：</span>
                    <span>{{enterpriseInfo?.epStyle}}</span>
                </div>
            </el-col>
             <el-col :span="8">
                 <div>
                    <span class="de_label">参保人数：</span>
                    <span>{{enterpriseInfo?.insuredperson ?enterpriseInfo?.insuredperson:'-'}}人</span>
                     <span  class=" fs-small" :class="insuredpersonRankinig?.className">
                        {{insuredpersonRankinig?.val}}
                        <el-tooltip v-if='insuredpersonRankinig'
                            effect="light"
                            content="本省同行业的企业"
                            placement="right-start">
                            <i class="el-icon-question" ></i>
                        </el-tooltip>
                     </span>
                </div>
            </el-col>
        </el-row>
        <el-row class="fontsize16 row">
             <el-col :span="8">
                <div>
                    <span class="de_label">地区：</span>
                    <span>{{enterpriseInfo?.region}}</span>
                </div>
            </el-col>
            <el-col :span="16">
                <div>
                    <span class="de_label">所属行业：</span>
                    <span> {{enterpriseInfo?.industry}}</span>
                </div>
            </el-col>
           
        </el-row>
        <el-row class="fontsize16 row">
            
            <el-col :span="16">
                <div>
                    <span class="de_label">地址：</span>
                    <span> {{enterpriseInfo?.address}}</span>
                </div>
            </el-col>
        </el-row>
        <el-row class="fontsize16 row">
            <el-col :span="24">
                <div>
                    <span class="de_label">经营范围：</span>
                    <span>{{enterpriseInfo?.scope}}</span>
                </div>
            </el-col>
        </el-row>
    </div>
     <el-affix  :offset="0">
         <div class="cd_page_nav">
            <span :class="{'current':selLabel=='bq'}" @click="goAnchor('bq')">企业标签</span>
            <span v-if='enterpriseInfo?.isListedCompany' :class="{'current':selLabel=='zh'}" @click="goAnchor('zh')">综合分析</span>
            <span v-if='enterpriseInfo?.isListedCompany' :class="{'current':selLabel=='ys'}" @click="goAnchor('ys')">因素分析</span>
            <span v-if='enterpriseInfo?.isListedCompany' :class="{'current':selLabel=='cj'}" @click="goAnchor('cj')">财务健康评价</span>
            <span v-if='enterpriseInfo?.isListedCompany' :class="{'current':selLabel=='db'}" @click="goAnchor('db')">杜邦分析</span>
         </div>
     
    </el-affix>
    <div style="margin:0 20px;">
        <div  class="cd_page_con" id="bq">
            <h3>———  企业标签  ———</h3>
            <div class="content">
                <el-tag  v-for="item in labelList" :key="item.labelId" @click="tagClick(item)" >{{item.labelName}}</el-tag>
            </div>
        </div>
        <div v-if='enterpriseInfo?.isListedCompany'  class="cd_page_con" id="zh">
            <h3>———  综合分析  ———</h3>
            <div class="content">
                <span class="fr">年报日期:{{new Date(curYearDate).getFullYear()}}年</span>
                <br>
                <el-row class="row_ay">
                    <el-col :span="6">
                        <div>
                            <span>主营业务收入</span>
                            <br>
                            <strong class="txt-color fontsize20">{{zyywsr?.itemValue}}万元</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>同比增长</span>
                            <br>
                            <strong class="txt-color fontsize20">{{zyywsr?.samePeriodSpeed.toFixed(2)}}%</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>行业平均</span>
                            <br>
                            <strong class="txt-color fontsize20">{{zyywsrAvg?zyywsrAvg:'-'}}万元</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>本省同行业排名</span>
                            <br>
                            <strong class="txt-color fontsize20">{{zyywsrRanking.rowNum}}/{{zyywsrRanking.totalCount}}</strong>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="row_ay">
                    <el-col :span="6">
                        <div>
                            <span>总资产利润率</span>
                            <br>
                            <strong class="txt-color fontsize20">{{zzclrv?.itemValue}}%</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>同比增长</span>
                            <br>
                            <strong class="txt-color fontsize20">-</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>行业平均</span>
                            <br>
                            <strong class="txt-color fontsize20">{{zzclrvAvg?zzclrvAvg:'-'}}%</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>本省同行业排名</span>
                            <br>
                            <strong class="txt-color fontsize20">{{zzclrvRanking.rowNum}}/{{zzclrvRanking.totalCount}}</strong>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="row_ay">
                    <el-col :span="6">
                        <div>
                            <span>增值税</span>
                            <br>
                            <strong class="txt-color fontsize20">-</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>同比增长</span>
                            <br>
                            <strong class="txt-color fontsize20">-</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>行业平均</span>
                            <br>
                            <strong class="txt-color fontsize20">-</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>本省同行业排名</span>
                            <br>
                            <strong class="txt-color fontsize20">-</strong>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="row_ay">
                    <el-col :span="6">
                        <div>
                            <span>职工薪酬</span>
                            <br>
                            <strong class="txt-color fontsize20">-</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>同比增长</span>
                            <br>
                            <strong class="txt-color fontsize20">-</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>行业平均</span>
                            <br>
                            <strong class="txt-color fontsize20">-</strong>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span>本省同行业排名</span>
                            <br>
                            <strong class="txt-color fontsize20">-</strong>
                        </div>
                    </el-col>
                </el-row>
                <div class="clearfix">
                    <div v-for="item in itemList" :key='item.title' :id='"curve_"+item.tbName.replace("t_ep_","").replace("_data","")' style="float:left;height:300px;width:calc(25% - 12px);border:1px solid rgba(64, 158, 255, 0.3); border-radius: 4px;background: rgba(63, 148, 253, 0.1);margin:5px;"></div>
                </div>
            </div>

        </div>
        <div v-if='enterpriseInfo?.isListedCompany' class="cd_page_con" id="ys">
            <h3>———  因素分析  ———</h3>
            <div class="content">
                <el-tabs
                    type="border-card"
                    class="cus-tabs">
                    <el-tab-pane v-for="d in dateList.slice(0,dateList.length-1)" :key="d" :label="d" >
                        <el-table :data="itemAnalysisList.filter(t=>new Date(t.date).getTime()==new Date(d).getTime())" height='470' stripe style="width: 100%">
                            <el-table-column prop="name" label="指标项" width="180" />
                            <el-table-column prop="value" label="本期值" />
                            <el-table-column prop="prevValue" label="上期值" />
                            <el-table-column prop="speed" label="变动幅度" >
                                 <template #default="scope">
                                    <div :class="{'red':scope.row.speed>0,'green':scope.row.speed<=0}">
                                        <span>{{ scope.row.speed.toFixed(2) }}%</span>
                                        <i class="fr" :class="{'el-icon-top':scope.row.speed>0,'el-icon-bottom':scope.row.speed<=0}"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="reason" label="变动原因" />
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div v-if='enterpriseInfo?.isListedCompany'  class="cd_page_con" id="cj">
            <h3>———  财务健康评价  ———</h3>
            <div class="content">
                <span style="padding-left:10px;border-left:3px solid #3F94FD;display: inline-block;margin-bottom: 10px;" class="fontsize16">2021年企业模型分析</span>   
                <el-row>
                    <el-col :span="6">
                        <div>
                            <span class="fontsize16">Z计分模型排名：<span class="warning">{{curZJFRanking?.rowNum}}/{{curZJFRanking?.totalCount}}</span></span>
                            <br>
                            <span class="gray">本省本行业</span>
                        </div>
                        <div id='curve_zjfRange' style="height:270px;width:100%;">
                            
                        </div>
                    </el-col>
                    <el-col :span="6" style="padding:0 20px;border-right:1px solid #a5a4a4;">
                            <el-row>
                                <el-col :span="6" class="row_col">
                                    <span>x1
                                        <el-tooltip
                                            effect="light"
                                            content="x1=（流动资产-流动负债）/资产总额"
                                            placement="right-start">
                                            <i class="el-icon-question" ></i>
                                        </el-tooltip>
                                    </span>
                                    <span class="block">{{curZJFInfo?.p60000352.toFixed(2)}}</span>
                                </el-col>
                                <el-col :span="12">
                                </el-col>
                                <el-col :span="6" class="row_col">
                                    <span>x2
                                        <el-tooltip
                                            effect="light"
                                            content="x2=未分配利润/资产总额"
                                            placement="right-start">
                                            <i class="el-icon-question" ></i>
                                        </el-tooltip>
                                    </span>
                                    <span class="block">{{curZJFInfo?.p60000353.toFixed(2)}}</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6" >
                                </el-col>
                                <el-col :span="2" class="x">
                                </el-col>
                                <el-col :span="8">
                                </el-col>
                                 <el-col :span="2"  class="y">
                                </el-col>
                                <el-col :span="6">
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="3" >
                                </el-col>
                                <el-col :span="18" style="padding:30px 0;text-align: center;">
                                    <span class="warning fontsize16">Z计分模型</span>
                                     <br>
                                    <span class="warning fontsize16" >{{curZJFInfo?.p60000356.toFixed(2)}}</span>
                                    <br>
                                    <span class="gray">z3=6.56x1+3.26x2+6.72x3+1.05x4</span>
                                    <br>
                                    <span class="gray">Z＜1.23时，风险很大；Z ≥2.9时，风险较小</span>
                                </el-col>
                                <el-col :span="3">
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6" >
                                </el-col>
                                <el-col :span="2" class="y1">
                                </el-col>
                                <el-col :span="8">
                                </el-col>
                                 <el-col :span="2"  class="x1">
                                </el-col>
                                <el-col :span="6">
                                </el-col>
                            </el-row>
                             <el-row>
                                <el-col :span="6" class="row_col">
                                    <span>x3
                                        <el-tooltip
                                            effect="light"
                                            content="x3=（利润总额+折旧+摊销+利息支出）/资产总额"
                                            placement="right-start">
                                            <i class="el-icon-question" ></i>
                                        </el-tooltip>
                                    </span>
                                    <span class="block">{{curZJFInfo?.p60000354.toFixed(2)}}</span>
                                </el-col>
                                <el-col :span="12">
                                </el-col>
                                <el-col :span="6" class="row_col">
                                    <span>x4
                                        <el-tooltip
                                            effect="light"
                                            content="x4=所有者权益/负债总额"
                                            placement="right-start">
                                            <i class="el-icon-question" ></i>
                                        </el-tooltip>
                                    </span>
                                    <span class="block">{{curZJFInfo?.p60000355.toFixed(2)}}</span>
                                </el-col>
                            </el-row>
                    </el-col>
                   
                    <el-col :span="6"  style="padding:0 20px;">
                        <el-row>
                                <el-col :span="6" class="row_col">
                                    <span>x1
                                        <el-tooltip
                                            effect="light"
                                            content="x1=（税前利润+折旧+递延税）/流动负债"
                                            placement="right-start">
                                            <i class="el-icon-question" ></i>
                                        </el-tooltip>
                                    </span>
                                    <span class="block">{{curBSLInfo?.p60000357.toFixed(2)}}</span>
                                </el-col>
                                <el-col :span="3">
                                </el-col>
                                <el-col :span="6" class="row_col">
                                    <span>x2
                                        <el-tooltip
                                            effect="light"
                                            content="x2=税前利润/营运资本"
                                            placement="right-start">
                                            <i class="el-icon-question" ></i>
                                        </el-tooltip>
                                    </span>
                                    <span class="block">{{curBSLInfo?.p60000358.toFixed(2)}}</span>
                                </el-col>
                                <el-col :span="3">
                                </el-col>
                                <el-col :span="6" class="row_col">
                                    <span>x3
                                        <el-tooltip
                                            effect="light"
                                            content="x3=股东权益/流动负债"
                                            placement="right-start">
                                            <i class="el-icon-question" ></i>
                                        </el-tooltip>
                                    </span>
                                    <span class="block">{{curBSLInfo?.p60000359.toFixed(2)}}</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6" >
                                </el-col>
                                <el-col :span="2" class="x">
                                </el-col>
                                <el-col :span="3">
                                </el-col>
                                <el-col :span="2" class="x2">
                                </el-col>
                                <el-col :span="3">
                                </el-col>
                                 <el-col :span="2"  class="y">
                                </el-col>
                                <el-col :span="6">
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="3" >
                                </el-col>
                                <el-col :span="18" style="padding:30px 0;text-align: center;">
                                    <span class="warning fontsize16">巴萨利模型</span>
                                     <br>
                                    <span class="warning fontsize16" >{{curBSLInfo?.p60000362.toFixed(2)}}</span>
                                    <br>
                                    <span class="gray">y=x1+x2+x3+x4+x5</span>
                                    <br>
                                    <span class="gray">y值高企业运营状况良好，实力强；y值低或负数均表明企业前景不佳。</span>
                                </el-col>
                                <el-col :span="3">
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6" >
                                </el-col>
                                <el-col :span="2" class="y1">
                                </el-col>
                                <el-col :span="8">
                                </el-col>
                                 <el-col :span="2"  class="x1">
                                </el-col>
                                <el-col :span="6">
                                </el-col>
                            </el-row>
                             <el-row>
                                <el-col :span="6" class="row_col">
                                    <span>x4
                                        <el-tooltip
                                            effect="light"
                                            content="x4=有形资产净值/负债总额"
                                            placement="right-start">
                                            <i class="el-icon-question" ></i>
                                        </el-tooltip>
                                    </span>
                                    <span class="block">{{curBSLInfo?.p60000360.toFixed(2)}}</span>
                                </el-col>
                                <el-col :span="12">
                                </el-col>
                                <el-col :span="6" class="row_col">
                                    <span>x5
                                        <el-tooltip
                                            effect="light"
                                            content="x5=营运资本/资产总额"
                                            placement="right-start">
                                            <i class="el-icon-question" ></i>
                                        </el-tooltip>
                                    </span>
                                    <span class="block">{{curBSLInfo?.p60000361.toFixed(2)}}</span>
                                </el-col>
                            </el-row>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <span class="fontsize16">巴萨利模型排名：<span class="warning">{{curBSLRanking?.rowNum}}/{{curBSLRanking?.totalCount}}</span></span>
                            <br>
                            <span class="gray">本省本行业</span>
                        </div>
                        <div id='curve_bslRange' style="height:270px;width:100%;">
                            
                        </div>
                    </el-col>
                   
                </el-row>
                <span style="padding-left:10px;border-left:3px solid #3F94FD;display: inline-block;margin: 10px 0;" class="fontsize16">企业健康趋势</span>   
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div id="curve_zjf"  style="height:300px;width:100%;">
                           
                        </div>
                    </el-col>
                   <el-col :span="12">
                        <div id="curve_bsl"  style="height:300px;width:100%;">
                           
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div  v-if='enterpriseInfo?.isListedCompany' class="cd_page_con" id="db">
            <h3>———  杜邦分析  ———</h3>
            <div class="content clearfix" >
                <span class="fr">年报日期:{{new Date(curYearDate).getFullYear()}}年</span>
                <div style='height:600px;max-width:1400px;margin: 0 auto;'>
                    <div style="float:left;width:12%; height:100%;">
                            <div style=" height:15%;">
                            
                            </div>
                            <div style=" height:20%;">
                                <div class="txt-border">
                                    <span class="txt">营业成本</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000348?dbfxInfo?.p60000348:'-'}}</span>
                                </div>
                            </div>
                    
                        
                            <div style=" height:20%;">
                                <div class="txt-border">
                                    <span class="txt">销售费用</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000349?dbfxInfo?.p60000349:'-'}}</span>
                                </div>
                            </div>
                        
                        
                            <div style=" height:20%;">
                                <div class="txt-border">
                                    <span class="txt">管理费用</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000350?dbfxInfo?.p60000350:'-'}}</span>
                                </div>
                            </div>
                        
                            <div style=" height:20%;">
                                <div class="txt-border">
                                    <span class="txt">财务费用</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000351?dbfxInfo?.p60000351:'-'}}</span>
                                </div>
                            </div>
                        
                    </div>
                    <div style="float:left;width:6%; height:100%;">
                        <div style="float:left;width:50%; height:100%;">
                                <div style="width:100%; height:18%;border-bottom:1px solid gray;">

                                </div>
                                <div style="width:100%; height:20%;border-bottom:1px solid gray;">

                                </div>
                                <div style="width:100%; height:20%;border-bottom:1px solid gray;">

                                </div>
                                <div style="width:100%; height:20%;border-bottom:1px solid gray;">

                                </div>
                            
                        </div>
                        <div style="float:left;width:50%; height:100%;">
                            
                                <div style="width:100%; height:18%;">

                                </div>
                                <div style="width:100%; height:10%;border-bottom:1px solid gray;border-left:1px solid gray;">

                                </div>
                                <div style="width:100%; height:50%;border-left:1px solid gray;">

                                </div>
                                
                        </div>
                    </div>
                    <div style="float:left;width:12%; height:100%;position: relative;">
                            <div style="width:100%; height:5%;">
                            </div>
                            <div style=" height:20%;">
                                <div class="txt-border">
                                    <span class="txt">营业收入</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000341?dbfxInfo?.p60000341:'-'}}</span>
                                </div>
                            </div>
                            <div style="width:100%; height:5%;position: absolute;top: 15%;text-align: center;">
                                <span>-</span>
                            </div>
                            <div style=" height:20%;">
                                <div class="txt-border">
                                    <span class="txt">全部成本</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000343?dbfxInfo?.p60000343:'-'}}</span>
                                </div>
                            </div>
                            <div style="width:100%; height:5%;position: absolute;top: 35%;text-align: center;">
                                <span>+</span>
                            </div>
                        
                            <div style=" height:20%;">
                                <div class="txt-border">
                                    <span class="txt">投资收益</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000345?dbfxInfo?.p60000345:'-'}}</span>
                                </div>
                            </div>
                            <div style="width:100%; height:5%;position: absolute;top: 55%;text-align: center;">
                                <span>-</span>
                            </div>
                            <div style=" height:20%;">
                                    <div class="txt-border">
                                        <span class="txt">所得税</span>
                                        <span class="txt-span-padding">{{dbfxInfo?.p60000346?dbfxInfo?.p60000346:'-'}}</span>
                                    </div>
                                </div>
                            <div style="width:100%; height:5%;position: absolute;top: 75%;text-align: center;">
                                <span>+</span>
                            </div>
                            <div style=" height:15%;">
                                <div class="txt-border">
                                    <span class="txt">其他</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000347?dbfxInfo?.p60000347:'-'}}</span>
                                </div>
                            </div>
                    </div>
                    <div style="float:left;width:6%; height:100%;">
                        <div style="float:left;width:50%; height:100%;">
                                <div style="width:100%; height:8%;border-bottom:1px solid gray;">

                                </div>
                                <div style="width:100%; height:20%;border-bottom:1px solid gray;">

                                </div>
                                <div style="width:100%; height:20%;border-bottom:1px solid gray;">

                                </div>
                                <div style="width:100%; height:20%;border-bottom:1px solid gray;">

                                </div>
                                <div style="width:100%; height:20%;border-bottom:1px solid gray;">

                                </div>
                        </div>
                        <div style="float:left;width:50%; height:100%;">
                            
                                <div style="width:100%; height:3%;border-bottom:1px solid gray;">

                                </div>
                                <div style="width:100%; height:85%;border-left:1px solid gray;">

                                </div>
                                
                        </div>
                    </div>
                    <div style="float:left;width:12%; height:100%;">
                        <div style="width:100%; height:50%;position: relative;">
                        
                            <div style=" height:55%;">
                                <div class="txt-border">
                                    <span class="txt">净利润</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000340?dbfxInfo?.p60000340:'-'}}</span>
                                </div>
                            </div>
                    
                            <div style="width:100%; height:10%;position: absolute;top: 30%;text-align: center;">
                                <span>/</span>
                            </div>
                            <div style=" height:45%;">
                                <div class="txt-border">
                                    <span class="txt">营业收入</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000341?dbfxInfo?.p60000341:'-'}}</span>
                                </div>
                            </div>
                        </div>
                        <div style="width:100%; height:50%;position: relative;">
                        
                                <div style=" height:55%;">
                                    <div class="txt-border">
                                        <span class="txt">营业收入</span>
                                        <span class="txt-span-padding">{{dbfxInfo?.p60000341?dbfxInfo?.p60000341:'-'}}</span>
                                    </div>
                                </div>
                    
                            <div style="width:100%; height:10%;position: absolute;top: 30%;text-align: center;">
                                <span>/</span>
                            </div>
                                <div style=" height:45%;">
                                    <div class="txt-border">
                                        <span class="txt">平均资产总额</span>
                                        <span class="txt-span-padding">{{dbfxInfo?.p60000342?dbfxInfo?.p60000342:'-'}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div style="float:left;width:6%; height:100%;">
                        <div style="width:100%; height:50%;">
                            <div style="float:left;width:50%; height:100%;">
                                    <div style="width:100%; height:7%;border-bottom:1px solid gray;">

                                    </div>
                                    <div style="width:100%; height:54.5%;border-bottom:1px solid gray;">

                                    </div>
                            </div>
                            <div style="float:left;width:50%; height:100%;">
                                
                                    <div style="width:100%; height:7%;">

                                    </div>
                                    <div style="width:100%; height:23.5%;border-bottom:1px solid gray;border-left:1px solid gray;">

                                    </div>
                                    <div style="width:100%; height:31%;border-left:1px solid gray;">

                                    </div>
                            </div>
                        </div>
                        <div style="width:100%; height:50%;">
                            <div style="float:left;width:50%; height:100%;">
                                    <div style="width:100%; height:7%;border-bottom:1px solid gray;">

                                    </div>
                                    <div style="width:100%; height:54.5%;border-bottom:1px solid gray;">

                                    </div>
                            </div>
                            <div style="float:left;width:50%; height:100%;">
                                
                                    <div style="width:100%; height:7%;">

                                    </div>
                                    <div style="width:100%; height:13%;border-bottom:1px solid gray;border-left:1px solid gray;">

                                    </div>
                                    <div style="width:100%; height:41%;border-left:1px solid gray;">

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div style="float:left;width:12%; height:100%;position: relative;">
                        <div  style="width:100%; height:10%;">
                        </div>
                        <div  style="width:100%; height:45%;">
                            
                                <div class="txt-border">
                                    <span class="txt">销售净利率</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000338?dbfxInfo?.p60000338:'-'}}</span>
                                </div>
                        </div>
                        <div style="width:100%; height:5%;position: absolute;top: 35%;text-align: center;">
                            <span>X</span>
                        </div>
                        <div  style="width:100%; height:45%;">
                        
                                <div class="txt-border">
                                    <span class="txt">总资产周转率</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000339?dbfxInfo?.p60000339:'-'}}</span>
                                </div>
                        </div>
                    </div>
                    <div style="float:left;width:6%; height:100%;">
                        <div style="float:left;width:50%; height:100%;">
                                <div style="width:100%; height:15%;border-bottom:1px solid gray;">

                                </div>
                                <div style="width:100%; height:44%;border-bottom:1px solid gray;">

                                </div>
                        </div>
                        <div style="float:left;width:50%; height:100%;">
                            
                                <div style="width:100%; height:15%;">

                                </div>
                                <div style="width:100%; height:8.5%;border-bottom:1px solid gray;border-left:1px solid gray;">

                                </div>
                                <div style="width:100%; height:35%;border-left:1px solid gray;">

                                </div>
                        </div>
                    </div>
                    <div style="float:left;width:12%; height:100%;">
                        <div  style="width:100%; height:47%;display:table;">
                            <div style="display: table-cell;vertical-align: middle;">
                                <div class="txt-border">
                                    <span class="txt">总资产收益率</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000336?dbfxInfo?.p60000336:'-'}}</span>
                                </div>
                            </div>
                        </div>
                        <div style="width:100%; height:6%;text-align: center;">
                            <span>X</span>
                        </div>
                        <div  style="width:100%; height:47%;display:table;">
                            <div style="display: table-cell;vertical-align: middle;">
                                <div class="txt-border">
                                    <span class="txt">权益乘数</span>
                                    <span class="txt-span-padding">{{dbfxInfo?.p60000337?dbfxInfo?.p60000337:'-'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="float:left;width:6%; height:100%;">
                            <div style="float:left;width:50%; height:100%;">
                                    <div style="width:100%; height:25%;border-bottom:1px solid gray;position: relative;">
                                        <i class="el-icon-caret-left caret-pos"></i>
                                    </div>
                                    <div style="width:100%; height:50%;border-bottom:1px solid gray;position: relative;">
                                        <i class="el-icon-caret-left caret-pos"></i>
                                    </div>
                            </div>
                            <div style="float:left;width:50%; height:100%;">
                                
                                    <div style="width:100%; height:25%;">

                                    </div>
                                    <div style="width:100%; height:25%;border-bottom:1px solid gray;border-left:1px solid gray;">

                                    </div>
                                    <div style="width:100%; height:25%;border-left:1px solid gray;">

                                    </div>
                            </div>
                    </div>
                    <div style="float:left;width:10%;height:100%;display:table;">
                        <div style="display:table-cell;vertical-align:middle;">
                            <div class="txt-border">
                                <span class="txt">净资产收益率</span>
                                <span class="txt-span-padding">{{dbfxInfo?.p60000335?dbfxInfo?.p60000335:'-'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {nextTick, onBeforeUnmount, onMounted, reactive, ref } from '@vue/runtime-core'
import {scrollTo} from '@/utils/scroll-to'

import {getRegionInfo,getCodeNicList} from '@/http/basicsApi.js'
import {getCurItemData} from '@/http/ecoApi.js'
import {getEpStyle,getCapitalTypeList,getEpInfo,getEpItemList,getRankingOfEp,getItemRankingOfEp,getItemDataDate,getLabelListOfEp,getItemDataOfEp,getItemDataListOfEp,getZJFRangeEpNum,getBSLRangeEpNum} from '@/http/epApi.js'
import * as echarts from 'echarts'
import { useRoute } from 'vue-router'
import {parseTime} from '@/utils/index.js'
//import download from '@/components/download'
//import {addSysLog} from '@/http/baseApi.js'
import Header from '@/components/Header'
export default {
    name:'EpAnalysis',
    components:{
        Header
    },
    setup() {
        const route=useRoute();//当前路由
        let echartsAry=ref([]);//echart对象
        //菜单定位
        // const ceiling=()=>{
        //         let selfScroll = document.documentElement.scrollTop || document.body.scrollTop;
        //         var menuTop = document.querySelector('.cd_page_nav').offsetTop
        //         //console.log(_self.scroll)
        //         console.log(menuTop)
        //         //滑动到指定位置菜单吸顶
        //         if (selfScroll > menuTop) {
        //             searchBarFixed.value = true
        //         } else {
        //             searchBarFixed.value = false
        //         }
        //         // //滑动到指定位置相应菜单高亮
        //         // var a_h = document.getElementById(objId).offset().top;
        //         // if (selfScroll < l_h) {
        //         //     this.active = 'activity'
        //         // } else if (selfScroll < r_h) {
        //         //     this.active = 'lastest'
        //         // } else if (selfScroll > r_h) {
        //         //     this.active = 'rank'
        //         // }
        // }
        //锚点跳转
        let selLabel=ref('bq');//选中的标签
        const goAnchor= (selector) => {
            selLabel.value=selector;
            const obj=document.querySelector('#'+selector);
                
            let to=obj.offsetTop-60;
            //document.querySelector('.current').classList.remove('current');
            //obj.classList.add("current");
            scrollTo('',to,800);
        }
        let currentDate=ref('');//指标项数据的最新时间
        let curYearDate=ref('');//指标项数据的最新年度时间
        let enterpriseInfo=ref(null);//企业信息
        let createDateRankinig=ref(null);//成立日期排名
        let insuredpersonRankinig=ref(null);//参保人数排名
        let registeredcapitalRankinig=ref(null);//注册资本排名
        let zyywsrRanking=ref('');//主营业务收入的本省本行业的排名
        let zzclrvRanking=ref('');//总资产利润率的本省本行业的排名
        let zyywsrAvg=ref('')//主营业务收入的行业平均
        let zzclrvAvg=ref('');//总资产利润率的行业平均
        let dateList=ref([]);//因素分析中的时间列表
        let dbfxInfo=ref(null);//杜邦分析
        let itemList=reactive([{ids:'60000300,60000301,60000304,60000314',tbName:'t_ep_chnl_data',title:'偿还能力',ecoIndustry:'30010017,30010018,30010021,30010031'},
                    {ids:'60000321,60000323,60000324,60000325',tbName:'t_ep_yynl_data',title:'营运能力',ecoIndustry:'30010038,30010040,30010041,30010042'},
                    {ids:'60000317,60000318,60000319,60000320',tbName:'t_ep_cznl_data',title:'成长能力',ecoIndustry:'30010034,30010035,30010036,30010037'},
                    {ids:'60000288,60000291,60000295,60000292',tbName:'t_ep_ylnl_data',title:'盈利能力',ecoIndustry:'30010005,30010008,30010012,30010009'}]);
        let itemAnalysisList=ref([]);
        //获取地区企业的详细信息
        const getEpInfoFun=async()=>{
           //获取指标项数据的最新时间
            await getItemDataDate("t_ep_zycwzb_data").then(res=>{
                currentDate.value=res.data;
            });
            let result=await getEpInfo(route.params.id);
            console.log(result);
            if(result.code==20000){
                enterpriseInfo.value=result.data;
                enterpriseInfo.value.capitalTypeName='';
                //企业年龄的计算
                if(enterpriseInfo.value.createDate){
                    let startTime= Date.parse(enterpriseInfo.value.createDate);
                    let endTime= Date.parse(parseTime(new Date(),'{y}-{m}-{d}'));
                    let dateSpan = endTime- startTime;
                    enterpriseInfo.value.age =(dateSpan / (365*24 * 3600 * 1000)).toFixed(2);
                }
                
                //注册资本类型
                if(enterpriseInfo.value.capitalType){
                    let capitalTypeList= await getCapitalTypeList();
                    if(capitalTypeList.code==20000 && capitalTypeList.data){
                        let capitalTypeAry=capitalTypeList.data.filter(t=>t.code==enterpriseInfo.value.capitalType);
                        if(capitalTypeAry.length==1){
                            enterpriseInfo.value.capitalTypeName=capitalTypeAry[0].name;
                        }
                    }
                }
                //获取所属行业名称
                let ecoIndustryCode='';
                if(enterpriseInfo.value.industrycodeB){
                    ecoIndustryCode=enterpriseInfo.value.industrycodeB;
                }else if(enterpriseInfo.value.industrycodeA){
                    ecoIndustryCode=enterpriseInfo.value.industrycodeA;
                }
                let ecoIndustryInfo= await getCodeNicList({ids:ecoIndustryCode});
                if(ecoIndustryInfo.code==20000 && ecoIndustryInfo.data){
                    enterpriseInfo.value.industry=ecoIndustryInfo.data[0].fullTitle;
                }
                //获取所在省市名称
                let regionCode='';
                if(enterpriseInfo.value.district){
                    regionCode=enterpriseInfo.value.district
                }else if(enterpriseInfo.value.city){
                    regionCode=enterpriseInfo.value.city
                }else if(enterpriseInfo.value.province){
                    regionCode=enterpriseInfo.value.province
                }
                if(regionCode.length>0){
                    const regionInfo= await getRegionInfo(regionCode);
                    //console.log(regionInfo);
                    if(regionInfo.code==20000){
                        enterpriseInfo.value.region=regionInfo.data.fullTitle;
                    }
                }
                if((enterpriseInfo.value.industrycodeA || enterpriseInfo.value.industrycodeB) && enterpriseInfo.value.province){
                    //企业年龄的排名
                    let rParams={epIds:route.params.id,
                        state:1,
                        industryCodeA:enterpriseInfo.value.industrycodeA,
                        industryCode:enterpriseInfo.value.industrycodeB,
                        province:enterpriseInfo.value.province, 
                        orderFields:'createDate',
                        orderType:'asc'
                    }
                    if(enterpriseInfo.value.createDate){
                        getRankingOfEpFun(rParams).then(res=>{
                        console.log(res);
                        createDateRankinig.value={
                            val:res,
                            className:res.indexOf('超过')>-1?'high-ranking':'low-ranking'
                        };
                        
                    });
                    }
                    
                    //console.log(createDateRankinig.value);
                    //企业参保人数的排名
                    if(enterpriseInfo.value.insuredperson){
                        rParams.orderFields='insuredperson';
                        rParams.orderType='desc';
                        getRankingOfEpFun(rParams).then(res=>{
                            insuredpersonRankinig.value={
                                val:res,
                                className:res.indexOf('超过')>-1?'high-ranking':'low-ranking'
                            };
                        });
                    }
                    //注册资本的排名
                    if(enterpriseInfo.value.epStyle && enterpriseInfo.value.registeredcapital){
                        //获取企业类型的分类
                        let epStyleList= await getEpStyle();
                        if(epStyleList.code==20000){
                            let fileterAry= epStyleList.data.filter(t=>enterpriseInfo.value.epStyle.indexOf(t.name)>-1);
                            if(fileterAry.length>0){
                                rParams.epStyle=fileterAry[0].id;
                                rParams.orderFields='registeredcapital';
                                rParams.orderType='desc';
                                getRankingOfEpFun(rParams).then(res=>{
                                    console.log(res);
                                    registeredcapitalRankinig.value={
                                        val:res,
                                        className:res.indexOf('超过')>-1?'high-ranking':'low-ranking'
                                    };
                                });
                            }
                        }
                        
                    }
        
                }

                if(!enterpriseInfo.value.isListedCompany){return false;}//上市公司才有下面的数据分析

                curYearDate.value=currentDate.value;
                //将季度日期转为年度日期
                if(new Date(currentDate.value).getMonth()+1<10){
                    curYearDate.value=(new Date(currentDate.value).getFullYear()-1)+'-10-01';
                }
                //获取企业当前时间指标项的值及增速
                getItemDataOfEpFun();
                if((enterpriseInfo.value.industrycodeA || enterpriseInfo.value.industrycodeB) && enterpriseInfo.value.province){
                    //主营业务收入的本省本行业的排名
                    getItemRankingOfEpFun({epIds:route.params.id,province:enterpriseInfo.value.province,industryCodeA:enterpriseInfo.value.industrycodeA,industryCode:enterpriseInfo.value.industrycodeB,date:curYearDate.value, orderFiled:'60000010'}).then(res=>{
                        zyywsrRanking.value=res;
                    }).catch(err => {
                        console.log(err)
                    });
                    //总资产利润率的本省本行业的排名
                    getItemRankingOfEpFun({epIds:route.params.id,province:enterpriseInfo.value.province,industryCodeA:enterpriseInfo.value.industrycodeA,industryCode:enterpriseInfo.value.industrycodeB,date:curYearDate.value, orderFiled:'60000285'}).then(res=>{
                        zzclrvRanking.value=res;
                    }).catch(err => {
                        console.log(err)
                    });   
                } 
                //当前时间的行业平均
                let avgParams={
                    regionCode:'0',
                    objId:enterpriseInfo.value.industrycodeB,
                    itemId:'30010000,30010002,30010017,30010018,30010021,30010031,30010038,30010040,30010041,30010042,30010034,30010035,30010036,30010037,30010005,30010008,30010012,30010009',
                    itemGrade:2,
                    date:curYearDate.value
                }
                let avgRes=await getCurItemData(avgParams);
                if(avgRes.code==20000){
                    console.log(avgRes);
                    let zAry=avgRes.data.filter(t=>t.itemId=='30010000');
                    if(zAry.length>0){
                        zyywsrAvg.value=zAry[0].itemValue;
                    }
                    let zzclrvAry=avgRes.data.filter(t=>t.itemId=='30010002');
                    if(zzclrvAry.length>0){
                        zzclrvAvg.value=zzclrvAry[0].itemValue;
                    }
                    //获取企业的成长能力、偿还能力、盈利能力、运营能力的相关指标项
                    //1、获取指标项信息
                    let itemRes= await getEpItemList({ids:itemList.map(t=>t.ids).join(',')});
                    //console.log(itemRes);
                    if(itemRes.code==20000){
                        itemList.forEach(el=>{
                            //2、获取指标数据
                            let params={
                                epId:route.params.id,
                                startDate:curYearDate.value,//数组日期列表中的最后一个日期
                                endDate:curYearDate.value,
                                tableName:el.tbName,
                                cols:el.ids.split(',').map(t=>{let field='p'+t;return field;}).join(','),
                                itemCycle:2,
                                cycle:1
                            }
                            //console.log(params);
                            getItemDataListOfEpFun(params).then(res=>{
                                console.log(res);
                                let indicatorAry=[];
                                let epValList=[];
                                let ecoIndustryValList=[];
                                let itemAry=el.ids.split(',');
                                for(let i=0;i<itemAry.length;i++){
                                    indicatorAry.push({text: itemRes.data.filter(t=>t.itemId==itemAry[i])[0]['itemName']});
                                    if(res.length>0){
                                        epValList.push(res[0]['p'+itemAry[i]]);
                                    }
                                    else{
                                        epValList.push(null);
                                    }
                                    
                                    let  avgAry= avgRes.data.filter(t=>t.itemId==el.ecoIndustry.split(',')[i])
                                    if(avgAry.length>0){
                                        ecoIndustryValList.push(avgAry[0].itemValue)
                                    }
                                    else{
                                        ecoIndustryValList.push(null)
                                    }
                                }
                                nextTick(()=>{
                                    let myChart =echarts.init(document.getElementById("curve_"+el.tbName.replace('t_ep_','').replace('_data','')));
                                    var option = {
                                    color: ['#67F9D8', '#FFE434', '#56A3F1', '#FF917C'],
                                    title: {
                                        text: el.title,
                                        textStyle:{
                                            
                                            fontSize:16,
                                            //fontWeight:'lighter',
                                        },
                                        left:10,
                                        top:10,
                                    },
                                    legend: { 
                                        orient:'vertical',
                                        right:5,
                                        bottom:5,
                                        itemWidth:16,
                                        itemHeight:12
                                        },
                                    radar: [
                                        {
                                        indicator: indicatorAry,
                                        center: ['50%', '50%'],
                                        radius: 95,
                                        startAngle: 60,
                                        shape: 'circle',
                                        axisName: {
                                            color: 'rgb(51, 51, 51)',
                                            backgroundColor: 'transparent',
                                            padding: [0, 0]
                                        },
                                        splitArea: {
                                            areaStyle: {
                                            color: ['rgba(64, 158, 255, 0.1)', 'transparent'],
                                            shadowColor: 'rgba(0, 0, 0, 0.2)',
                                            shadowBlur: 10
                                            }
                                        },
                                        axisLine: {
                                            lineStyle: {
                                            color: 'rgba(64, 158, 255, 0.3)'
                                            }
                                        },
                                        splitLine: {
                                            lineStyle: {
                                            color: 'rgba(64, 158, 255, 0.3)'
                                            }
                                        }
                                        },
                                    ],
                                    series: [
                                        {
                                        type: 'radar',
                                        data: [
                                            {
                                            value: ecoIndustryValList,
                                            name: '行业平均',
                                            symbol: 'rect',
                                            symbolSize: 6,
                                            lineStyle: {
                                                type: 'dashed'
                                            },
                                            label: {
                                                show: true,
                                                formatter: function (params) {
                                                return params.value;
                                                }
                                            }
                                            },
                                            {
                                            value: epValList,
                                            name: '企业',
                                            areaStyle: {
                                                color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                                                {
                                                    color: 'rgba(255, 145, 124, 0.1)',
                                                    offset: 0
                                                },
                                                {
                                                    color: 'rgba(255, 145, 124, 0.9)',
                                                    offset: 1
                                                }
                                                ])
                                            }
                                            }]
                                        }]
                                    };
                                    myChart.setOption(option);
                                    echartsAry.value.push(myChart);
                                });
                            });
                        })
                    }
                }

                //财务健康评价
                //1、Z计分模型
                getZJFInfo(curYearDate.value,enterpriseInfo.value.province,enterpriseInfo.value.industrycodeA,enterpriseInfo.value.industrycodeB);
                //2、巴萨利模型
                getBSLInfo(curYearDate.value,enterpriseInfo.value.province,enterpriseInfo.value.industrycodeA,enterpriseInfo.value.industrycodeB);
                
                //因素分析
                //1、日期列表,多一个日期为了计算增速
                for(let i=0;i<7;i++){
                    dateList.value.push(parseTime(new Date(currentDate.value).setMonth(-i*3),'{y}-{m}-{d}'));
                }
                let changeReasons = [{ "id": '60000300','tbName':'t_ep_chnl_data', "reason": ["资金流动性好或者存活过多，货物积压，企业有较多资金滞留在流动资产", "资金流动性差，负债增加"] }
                            , { "id": '60000304','tbName':'t_ep_chnl_data', "reason": ["银行贷款增加，利润的增幅小于资产负债率增幅", "资金充足，利润较上一周期有所增长，所有者权益增加"] }
                            , { "id": '60000314','tbName':'t_ep_chnl_data', "reason": ["企业增加了股权投资或减少了向债权人举债", "偿还了部分债务，盈利增加，以股权融资的方式增加了资产"] }
                            
                            , { "id": '60000323','tbName':'t_ep_yynl_data', "reason": ["资资金使用效率提高，货物流动性增强", "企业存货资产变现能力降低，库存周转时间变长"] }
                            , { "id": '60000321','tbName':'t_ep_yynl_data', "reason": ["资产流动快，收款速度快，应收账款周转天数短", "客户拖延还款，客户财务困难，赊销比率过高"] }
                            
                            , { "id": '60000324','tbName':'t_ep_yynl_data', "reason": ["企业固定资产利用越充分，总资产的周转速度增强", "销售额偏低，企业获利能力下降，固定资产利用不充分"] }
                            , { "id": '60000291','tbName':'t_ep_ylnl_data', "reason": ["企业产品净利润空间大，成本降低", "利润率下降，成本上升"] }
                            , { "id": '60000288','tbName':'t_ep_ylnl_data', "reason": ["销售利润上升，成本下降", "销售利润下滑，成本上升"] }
                            , { "id": '60000295','tbName':'t_ep_ylnl_data', "reason": ["企业投入产出越好，资产利用率越高，企业收入增加", "资产利用率低，资产获利能力降低"] }];
                let itemRes= await getEpItemList({ids:changeReasons.map(t=>t.id).join(',')});
                //console.log(itemRes);
                if(itemRes.code==20000){
                    //根据表名分组
                    let tbAry= itemRes.data.map(t=>t.targetTable);
                    let tbList=[...new Set(tbAry)];
                    tbList.forEach(el=>{
                        let filterAry= itemRes.data.filter(it=>it.targetTable==el);
                        let params={
                            epId:route.params.id,
                            startDate:dateList.value[dateList.value.length-1],//数组日期列表中的最后一个日期
                            endDate:dateList.value[0],
                            tableName:el,
                            cols:filterAry.map(i=>{let i_='p'+i.itemId;return i_}).join(','),
                            itemCycle:filterAry[0].itemCycle,
                            cycle:filterAry[0].itemCycle
                        }
                        //console.log(params);
                        getItemDataListOfEpFun(params).then(res=>{
                            console.log(res);
                            //4、计算当前日期的指标变动幅度
                            for(let i=0;i<res.length-1;i++){
                                let curVal=res[i];
                                let curDate=new Date(curVal.date);
                                curDate= curDate.setMonth(curDate.getMonth()-3); 
                                let prevAry=res.filter(t=>new Date(t.date).getTime()==new Date(curDate).getTime());//季度数据
                                let prevVal=null;
                                if(prevAry.length>0){
                                    prevVal=prevAry[0];
                                }
                                //循环指标项
                                filterAry.forEach(it=>{
                                    let field='p'+it.itemId;
                                    let changeAry=changeReasons.filter(t=>t.id==it.itemId);
                                    if(curVal[field] &&  prevVal && prevVal[field] && prevVal[field]!=0){
                                        let speed=(curVal[field]-prevVal[field])/prevVal[field]*100;
                                        let reason='';
                                        if(speed>0){
                                            reason=changeAry[0]['reason'][0];
                                        }
                                        else {
                                            reason=changeAry[0]['reason'][1];
                                        }
                                        itemAnalysisList.value.push({name:it.itemName,date:curVal['date'], value:curVal[field],prevValue:prevVal[field], speed:speed,reason:reason});
                                    }
                                })
                            }
                            //console.log(itemAnalysisList.value);
                        });
                    })
                }
                //杜邦分析
                let dbParams={
                    epId:route.params.id,
                    startDate:curYearDate.value,
                    endData:curYearDate.value,
                    tableName:'t_ep_dbfx_data',
                    cols:'p60000335,p60000336,p60000337,p60000338,p60000339,p60000340,p60000341,p60000342,p60000343,p60000345,p60000346,p60000347,p60000348,p60000349,p60000350,p60000351',
                    itemCycle:2,
                    cycle:1
                }
                getItemDataListOfEpFun(dbParams).then(res=>{
                    //console.log(res);
                    if(res && res.length>0){
                        dbfxInfo.value=res[0];
                    }
                });
            }
            
        }
        
        let zyywsr=ref(null);//主营业务收入
        let zzclrv=ref(null);//总资产利润率
        //获取企业当前时间指标项的值及增速
        const getItemDataOfEpFun=async()=>{
            let params={
                itemId:'60000010,60000285',
                epId:route.params.id,
                date:curYearDate.value,
                cycle:1//按年度显示
            }
          let res=  await getItemDataOfEp(params);
          //console.log(res);
          if(res.code==20000){
              let zyywsrAry= res.data.filter(t=>t.itemId=='60000010');
              if(zyywsrAry.length>0){
                  zyywsr.value=zyywsrAry[0] ;
              }
              let zzclrvAry=res.data.filter(t=>t.itemId=='60000285');
              if(zzclrvAry.length>0)
              {
                  zzclrv.value=zzclrvAry[0];
              }
              
          }
        }
        
        //获取企业信息排名
        const getRankingOfEpFun=async(data)=>{
            let result='';
            let res1 =await getRankingOfEp(data);
            console.log(res1);
            if(res1.code==20000 && res1.data.dataList.length>0){
                let num=res1.data.dataList[0].rowNum/ res1.data.totalCount;
                if(num>0.5){
                    result='低于'+parseInt(num*100)+'%';
                }
                else{
                    result='超过'+parseInt((1-num)*100)+'%';
                }
            }
            return result;
        }
        // 获取企业指标项排名
        const getItemRankingOfEpFun=async(data)=>{
            let result='';
            let res1 =await getItemRankingOfEp(data);
            console.log(res1);
            if(res1.code==20000 && res1.data.dataList.length>0){
                result={rowNum:res1.data.dataList[0].rowNum, totalCount:res1.data.totalCount};
            }
            return result;
        }
        //企业的标签列表
        let labelList=ref(null);
        const getEpLabelFun=async()=>{
            let params={
                epId:parseInt(route.params.id),
                pageIndex:1,
                pageSize:1
            }
            //console.log(params);
            let result=await getLabelListOfEp(params);
            if(result.code==20000){
                //console.log(result);
                labelList.value=result.data.dataList
            }
        }
        //获取企业的近时间段的指标数据
        const getItemDataListOfEpFun=async(data)=>{
            let result=null;
            let res=await getItemDataListOfEp(data)
            if(res.code==20000){
                result=res.data;
            }
            return result;
        }
        let curZJFInfo=ref(null);//最新年份的z计分模型各参数值
        let curZJFRanking=ref(null);//最新年份的z值的排名
        const getZJFInfo=(yearDate,province,industryCodeA,industryCodeB)=>{
             //最新年份的Z计分模型数据
            let params={
                epId:route.params.id,
                startDate:yearDate,
                endDate:yearDate,
                tableName:'t_ep_zjf_data',
                cols:'p60000352,p60000353,p60000354,p60000355,p60000356',
                itemCycle:2,
                cycle:1
            }
            //console.log(params);
            
            getItemDataListOfEpFun(params).then(res=>{
                if(res && res.length>0){
                    curZJFInfo.value=res[0];
                }
                //console.log(curZJFInfo.value);
            })
            //历年的Z值曲线,折线
            
            let chartDate=[];//时间轴
            for(let i=0;i<5;i++){
                let year=new Date(curYearDate.value).getFullYear()-i;
                chartDate.unshift(parseTime(new Date(curYearDate.value).setFullYear(year),'{y}-{m}-{d}'));
            }
            params.cols="p60000356";
            params.startDate=chartDate[0];
            getItemDataListOfEpFun(params).then(res=>{
                console.log(res);
                //数据要补全时间
                let dtList=[];
                chartDate.forEach(d=>{
                    let filterAry=res.filter(el=>new Date(el.date).getTime()==new Date(d).getTime());
                    if(filterAry.length>0){
                        dtList.push(filterAry[0][params.cols]);
                    }
                    else{
                        dtList.push('-');
                    }
                })
                nextTick(()=>{
                    var chart = echarts.init(document.getElementById("curve_zjf"));
                        var option = {
                            title: {
                                text: 'Z计分模型',
                                textStyle: {
                                    align: 'center',
                                    fontWeight: 100,
                                    fontSize: 14,
                                },
                                top: '1%',
                                left: 'left',
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    lineStyle: {
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0,
                                                color: 'rgba(0, 255, 233,0)'
                                            }, {
                                                offset: 0.5,
                                                color: 'rgba(255, 255, 255,1)',
                                            }, {
                                                offset: 1,
                                                color: 'rgba(0, 255, 233,0)'
                                            }],
                                            global: false
                                        }
                                    },
                                },
                            },
                            grid: {
                                top: '18%',
                                left: '5%',
                                right: '5%',
                                bottom: '15%',
                                // containLabel: true
                            },
                            xAxis: [{
                                type: 'category',
                                axisLine: {
                                    show: true
                                },
                                splitArea: {
                                    // show: true,
                                    color: '#f00',
                                    lineStyle: {
                                        color: '#f00'
                                    },
                                },
                                
                                // axisLine: {
                                //     lineStyle: {
                                //         color: '#999',
                                //     }
                                // },
                                splitLine: {
                                    show: false
                                },
                                boundaryGap: false,
                                data: chartDate.map(function (item) {
                                    return new Date(item).getFullYear()+'年';
                                }),
                            }],

                            yAxis: [{
                                type: 'value',
                                axisLabel: {
                                    show: true,
                                    
                                },
                                axisTick: {
                                    length: 3,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#656973',
                                    }
                                },
                                splitLine: {
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#525252'
                                    },

                                },
                            }],
                            series: [{
                                name: 'Z计分模型' ,
                                type: 'line',
                                smooth: true, //是否平滑
                                showAllSymbol: true,
                                // symbol: 'image://./static/images/guang-circle.png',
                                symbol: 'circle',
                                symbolSize: 15,
                                lineStyle: {
                                    normal: {
                                        color: 'rgba(0,179,244,1)',
                                        shadowColor: 'rgba(0, 0, 0, .3)',
                                        shadowBlur: 0,
                                        shadowOffsetY: 5,
                                        shadowOffsetX: 5,
                                    },
                                },
                                label: {
                                    show: true,
                                    position: 'top',
                                    textStyle: {
                                        color: 'rgba(0,179,244,1)',
                                    }
                                },
                                itemStyle: {
                                    color: 'rgba(0,179,244,1)',
                                    borderColor: "#fff",
                                    borderWidth: 3,
                                    shadowColor: 'rgba(0, 0, 0, .3)',
                                    shadowBlur: 0,
                                    shadowOffsetY: 2,
                                    shadowOffsetX: 2,
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                            offset: 0,
                                            color: 'rgba(0,179,244,0.3)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(0,179,244,0)'
                                        }
                                        ], false),
                                        shadowColor: 'rgba(0,179,244,0.9)',
                                        shadowBlur: 20
                                    }
                                },
                                data: res.map(function (item) {
                                    return  Math.floor(item[params.cols]*100)/100;
                                }),
                            }
                            ]
                        };
                        chart.setOption(option);
                        echartsAry.value.push(chart);
                })
            })
            //最新年份的Z值的排名，本省本行业
            if(province && (industryCodeA || industryCodeB)){
                getItemRankingOfEpFun({epIds:route.params.id,province:province,industryCodeA:industryCodeA,industryCode:industryCodeB,date:yearDate, orderFiled:'60000356'}).then(res=>{
                    curZJFRanking.value=res;
                }).catch(err => {
                    console.log(err)
                });
            }
            //最新年份的z值不同范围的企业量，金字塔
            if(industryCodeA || industryCodeB){
                getZJFRangeEpNum(industryCodeA,industryCodeB,yearDate).then(res=>{
                    console.log(res);
                if(res.code==20000){
                    nextTick(()=>{
                        var chart1 = echarts.init(document.getElementById('curve_zjfRange'));
                        chart1.clear();
                        var option = {
                            color: ['#4BC4A2', '#4E6EDF', '#3E4C9E'],
                            title: {
                                text: '本行业的企业Z计分模型分布',
                                bottom: '2',
                                left: 'center',
                                textStyle: {
                                    fontWeight: 100,
                                    fontSize:12,
                                }
                            },
                            calculable: true,
                            // grid: {
                            //     top: 5,
                            //     left: 20,
                            //     right: 50,
                            //     bottom: 10,
                            //     // containLabel: true
                            // },
                            series: [{
                                name: 'z值分布',
                                type: 'funnel',
                                left: '10%',
                                right: '10%',
                                top: '1%',
                                bottom:30,
                                sort: 'ascending',
                                label: {
                                    normal: {
                                        position: 'inside',
                                        //padding: [0, 0, -80, 0],
                                        formatter: function (params) {
                                            var text = '{a|' + params.data.num+'}{b|家}\n{c|' + params.name+'}'
                                            return text
                                        },
                                        rich: {
                                            a: {
                                                color: '#fdde2c',
                                                fontSize: '18',
                                                align: 'center',
                                                border: 0
                                            },
                                            b: {
                                                fontSize: '12',
                                                color: '#fff',
                                                border: 0
                                            },
                                            c: {
                                                color: '#fff',
                                                fontSize: '12',
                                                align: 'center',
                                                border: 0
                                            },
                                        }
                                    },
                                },
                                data: res.data.map((t,i)=>{
                                    return {
                                        value:20*(i+1),
                                        name:t.name,
                                        num:t.num
                                    }
                                }),
                            }],
                            
                        };
                        chart1.setOption(option);
                        echartsAry.value.push(chart1);
                    })
                }
                });
            }
        }
        let curBSLInfo=ref(null);//最新年份的z计分模型各参数值
        let curBSLRanking=ref(null);//最新年份的z值的排名
        const getBSLInfo=(yearDate,province,industryCodeA,industryCodeB)=>{
             //最新年份的Z计分模型数据
            let params={
                epId:route.params.id,
                startDate:yearDate,
                endDate:yearDate,
                tableName:'t_ep_bsl_data',
                cols:'p60000357,p60000358,p60000359,p60000360,p60000361,p60000362',
                itemCycle:2,
                cycle:1
            }
            //console.log(params);
            
            getItemDataListOfEpFun(params).then(res=>{
                if(res && res.length>0){
                    curBSLInfo.value=res[0];
                }
            })
            //历年的Z值曲线,折线
            
            let chartDate=[];//时间轴
            for(let i=0;i<5;i++){
                let year=new Date(curYearDate.value).getFullYear()-i;
                chartDate.unshift(parseTime(new Date(curYearDate.value).setFullYear(year),'{y}-{m}-{d}'));
            }
            params.cols="p60000362";
            params.startDate=chartDate[0];
            getItemDataListOfEpFun(params).then(res=>{
                console.log(res);
                //数据要补全时间
                let dtList=[];
                chartDate.forEach(d=>{
                    let filterAry=res.filter(el=>new Date(el.date).getTime()==new Date(d).getTime());
                    if(filterAry.length>0){
                        dtList.push(filterAry[0][params.cols]);
                    }
                    else{
                        dtList.push('-');
                    }
                })
                nextTick(()=>{
                    var chart2 = echarts.init(document.getElementById("curve_bsl"));
                        var option = {
                            title: {
                                text: '巴萨利模型',
                                textStyle: {
                                    align: 'center',
                                    fontWeight: 100,
                                    fontSize: 14,
                                },
                                top: '1%',
                                left: 'left',
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    lineStyle: {
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0,
                                                color: 'rgba(0, 255, 233,0)'
                                            }, {
                                                offset: 0.5,
                                                color: 'rgba(255, 255, 255,1)',
                                            }, {
                                                offset: 1,
                                                color: 'rgba(0, 255, 233,0)'
                                            }],
                                            global: false
                                        }
                                    },
                                },
                            },
                            grid: {
                                top: '18%',
                                left: '5%',
                                right: '5%',
                                bottom: '15%',
                                // containLabel: true
                            },
                            xAxis: [{
                                type: 'category',
                                axisLine: {
                                    show: true
                                },
                                splitArea: {
                                    // show: true,
                                    color: '#f00',
                                    lineStyle: {
                                        color: '#f00'
                                    },
                                },
                                // axisLine: {
                                //     lineStyle: {
                                //         color: '#999',
                                //     }
                                // },
                                splitLine: {
                                    show: false
                                },
                                boundaryGap: false,
                                data: chartDate.map(function (item) {
                                    return new Date(item).getFullYear()+'年';
                                }),
                            }],

                            yAxis: [{
                                type: 'value',
                                axisLabel: {
                                    show: true,
                                },
                                axisTick: {
                                    length: 3,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#656973',
                                    }
                                },
                                splitLine: {
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#525252'
                                    },

                                },
                            }],
                            series: [{
                                name: '巴萨利模型' ,
                                type: 'line',
                                smooth: true, //是否平滑
                                showAllSymbol: true,
                                // symbol: 'image://./static/images/guang-circle.png',
                                symbol: 'circle',
                                symbolSize: 15,
                                lineStyle: {
                                    normal: {
                                        color: 'rgba(0,202,149,1)',
                                        shadowColor: 'rgba(0, 0, 0, .3)',
                                        shadowBlur: 0,
                                        shadowOffsetY: 5,
                                        shadowOffsetX: 5,
                                    },
                                },
                                label: {
                                    show: true,
                                    position: 'top',
                                    textStyle: {
                                        color: 'rgba(0,202,149,1)',
                                    }
                                },
                                itemStyle: {
                                    color: 'rgba(0,202,149,1)',
                                    borderColor: "#fff",
                                    borderWidth: 3,
                                    shadowColor: 'rgba(0, 0, 0, .3)',
                                    shadowBlur: 0,
                                    shadowOffsetY: 2,
                                    shadowOffsetX: 2,
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                            offset: 0,
                                            color: 'rgba(0,202,149,0.3)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(0,202,149,0)'
                                        }
                                        ], false),
                                        shadowColor: 'rgba(0,202,149,0.9)',
                                        shadowBlur: 20
                                    }
                                },
                                data: res.map(function (item) {
                                    return  Math.floor(item[params.cols]*100)/100;
                                }),
                            }
                            ]
                        };
                        chart2.setOption(option);
                        echartsAry.value.push(chart2);
                })
            })
            //最新年份的Z值的排名，本省本行业
            if(province && (industryCodeA || industryCodeB)){
                getItemRankingOfEpFun({epIds:route.params.id,province:province,industryCodeA:industryCodeA,industryCode:industryCodeB,date:yearDate, orderFiled:'60000362'}).then(res=>{
                    curBSLRanking.value=res;
                }).catch(err => {
                    console.log(err)
                });
            }
            //最新年份的z值不同范围的企业量，金字塔
            if(industryCodeA || industryCodeB){
                getBSLRangeEpNum(industryCodeA,industryCodeB,yearDate).then(res=>{
                    console.log(res);
                if(res.code==20000){
                    nextTick(()=>{
                        var chart3 = echarts.init(document.getElementById('curve_bslRange'));
                        chart3.clear();
                        var option = {
                            color: ['#4BC4A2', '#4E6EDF', '#3E4C9E'],
                            title: {
                                text: '本行业的企业巴萨利模型分布',
                                bottom: '2',
                                left: 'center',
                                textStyle: {
                                    fontWeight: 100,
                                    color: '#fff',
                                    fontSize:12,
                                }
                            },
                            calculable: true,
                            series: [{
                                name: '巴萨利模型值分布',
                                type: 'funnel',
                                left: '10%',
                                right: '10%',
                                top: '1%',
                                bottom:30,
                                sort: 'ascending',
                                label: {
                                    normal: {
                                        position: 'inside',
                                        //padding: [0, 0, -80, 0],
                                        formatter: function (params) {
                                            var text = '{a|' + params.data.num+'}{b|家}\n{c|' + params.name+'}'
                                            return text
                                        },
                                        rich: {
                                            a: {
                                                color: '#fdde2c',
                                                fontSize: '18',
                                                align: 'center',
                                                border: 0
                                            },
                                            b: {
                                                fontSize: '12',
                                                color: '#fff',
                                                border: 0
                                            },
                                            c: {
                                                color: '#fff',
                                                fontSize: '12',
                                                align: 'center',
                                                border: 0
                                            },
                                        }
                                    },
                                },
                                data: res.data.map((t,i)=>{
                                    return {
                                        value:20*(i+1),
                                        name:t.name,
                                        num:t.num
                                    }
                                }),
                            }],
                            
                        };
                        chart3.setOption(option);
                        echartsAry.value.push(chart3);
                    })
                }
                });
            }
        }
        
        //自适应
        let listener=()=> {
            echartsAry.value.forEach(element => {
            element.resize();
          });
        }
        window.addEventListener('resize', listener)//监听resize事件
        //滚动监听事件
        //window.addEventListener('scroll', ceiling)
        // const addSysLogFun= async(operation,data)=>{
        //     let params={
        //         menuId:route.meta.menuId,
        //         operation: operation,
        //         operationParams:JSON.stringify(data)
        //     };
        //     await addSysLog(params);
        // }
        // addSysLogFun('重点画像',{entId:route.params.id});

        //获取企业信息
        getEpInfoFun();
        
        
        
        //获取标签列表
        getEpLabelFun();
        
        onMounted(()=>{

        })
        onBeforeUnmount(()=>{//销毁之前
            //window.removeEventListener('scroll',ceiling)
             window.removeEventListener('resize',listener);
             echartsAry.value.forEach(element => {
                if (element) {
                    element.clear(); //清空图表
                    element.dispose(); //释放图表组件
                    element = null;
                }
            }); 
        })
        return{
            selLabel,
            goAnchor,
            enterpriseInfo,
            labelList,
            parseTime,
            curYearDate,
            zyywsr,//主营业务收入
            zyywsrRanking,//主营业务收入排名
            zyywsrAvg,//主营业务收入的行业平均
            zzclrv,//总资产利润率
            zzclrvRanking,//总资产利润率排名
            zzclrvAvg,//总资产利润率的行业平均
            itemList,
            dateList,
            itemAnalysisList,
            curZJFInfo,//最新年份的z计分模型各参数值
            curZJFRanking,//最新年份的z值的排名
            curBSLInfo,
            curBSLRanking,
            dbfxInfo,
            createDateRankinig,
            insuredpersonRankinig,
            registeredcapitalRankinig//注册资本排名

        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.row{
    line-height: 30px;
    margin:5px 0px;
}
.high-ranking{
    background: rgba(192,54,57,0.2);
    color: $red;
    padding:0 5px;
    border-radius: 20px;
    margin-left:5px;
}
.low-ranking{
    background: rgba(48,176,143,0.2);
    color: #30B08F;
    padding:0 5px;
    border-radius: 20px;
    margin-left:5px;
}
.row_ay{
    background: rgba(64, 158, 255, 0.1);
    padding: 10px;
    font-size: 14px;
    text-align: center;
    margin: auto;
    margin-bottom:10px;
    width:85%;
}

.de_label{
    margin-right: 10px;
    width: 160px;
    color:grey;
}
.cd_page_nav{
    height: 45px;
    padding:0 20px;
    background-color: $themeColor;
}
.cd_page_nav span {
    float: left;
    font-size: 18px;
    border-bottom: 2px solid transparent;
    line-height: 42px;
    margin-right: 60px;
    cursor: pointer;
    color:#fff;
}
.cd_page_nav span.current {
    border-color: #b6fbff;
    color:#b6fbff;
}
.cd_page_nav span:hover {
    color:#b6fbff;
}
.cd_page_con{
    // 
    border-radius: 2px;
    position: relative;
    margin-bottom: 20px;
    h3 {
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: $specialtext-color;
        font-size: 20px;
        margin-bottom: 0;
        background: linear-gradient(180deg,rgba($themeColor, 0.1),rgba($themeColor, 0.01));
    }
    div.content{
        background-color: #fff;
        padding: 20px;
        position: relative;
    }

}
.txt-border{
    border:1px solid rgba($themeColor, 0.3);
    border-radius: 2px;
}
.txt {
    display: block;
    border: none;
    margin: 0;
    padding: 5px;
    background: rgba($themeColor, 0.1);
    overflow: hidden;
}
.txt-span-padding{
    padding: 5px;
    display: inline-block;
}

.bar{
    height: 6px;
    border-radius: 100px;
    background-color: rgba(0,0,0,.3);
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    margin: 8px 6px 6px 0;
}

.bar_inner {
    width: 0%;
    animation-duration: 3s;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: rgba(73,146,255,1); /* 不支持线性的时候显示 */
    background-image: linear-gradient(to right, rgba(73,146,255,0.8) , rgba(124,255,178,0.8));
    text-align: right;
    border-radius: 100px;
    line-height: 1;
    white-space: nowrap;
    transition: width .6s ease;
}
.row_col{
    background: radial-gradient(rgba(64, 158, 255, 0.2), transparent);
    padding: 10px;
    text-align: center;
    color:$specialtext-color;
}
.x,.y,.x1,.y1,.x2{
    position: relative;
    height: 30px;
}
.x::before,.x1::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #a5a4a4;
    transform-origin: bottom center;
    transform: rotateZ(45deg) scale(1.414);
    animation: slash 5s infinite ease;
}
.y::before,.y1::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #a5a4a4;
    transform-origin: bottom center;
    transform: rotateZ(-45deg) scale(1.414);
    animation: slash 5s infinite ease;
}
.y1::before,.x1::before{
    height: 0px;
}
.x2::before {
    content: "";
    position: absolute;
    left: 33.3%;
    top: 0;
    width: 60%;
    height: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #a5a4a4;
    transform-origin: bottom center;
    transform: rotateZ(90deg) scale(1.414);
    animation: slash 5s infinite ease;
}
.caret-pos{
    position: absolute;
    bottom: -7px;
    left: -3px;
    color: gray;
}
.neirong{
    background:#D4AE46;
    color: white;
    border-radius: 2px;
    padding: 2px 5px;
    display: inline-block;
    margin-bottom: 10px;
}

</style>